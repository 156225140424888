
































































































































import { Component, Vue } from 'vue-property-decorator';
import FlagshipGoalsWizardRetirementFundSecondModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-wizard-retirement-fund-secon-view-model';

@Component({
  name: 'FlagshipGoalsWizardRetirementFundSecond',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class FlagshipGoalsWizardRetirementFundSecond extends Vue {
  flagship_retirement_fund_second_model = Vue.observable(
    new FlagshipGoalsWizardRetirementFundSecondModel(this),
  );

  created() {
    this.flagship_retirement_fund_second_model.initialize();
  }
}
